<script setup>

import { onMounted, ref, watch } from "vue"
import { useRoute } from 'vue-router'

import { useStatisticStore } from '@/store/statistic'
import { useUserStore } from "@/store/user"

const statisticStore = useStatisticStore()
const userStore = useUserStore()

const route = useRoute()

const user = ref({})
const loading = ref(true)

const from = ref(new Date(new Date().setDate(new Date().getDate() - 30)))
const to = ref(new Date())

const ordersChart = ref({
  labels: [],
  datasets: [
    {
      data: [],
      backgroundColor: ['rgba(100, 181, 246, 0.2)'],
      borderColor: ['#64B5F6'],
      borderWidth: 3,
      fill: true,
      tension: .4
    },
  ],
})
const ordersChartOptions = ref({
  plugins: {
    legend: { display: false, }
  },
  responsive: true,
  hover: { mode: 'index' },
  scale: {
    ticks: { precision: 0 },
    y: { min: 0 }
  }
})

const search = () => {
  loading.value = true
  statisticStore.adminStats(from.value, to.value, route.params.id).then((res) => {
    ordersChart.value.labels = res.data.labels
    ordersChart.value.datasets[0].data = res.data.data
    
    /*
    let max = Math.max(...res.data.data)
    const lastNumber = max.toString().split('').pop()
    max += 10 - lastNumber
    ordersChartOptions.value.scale.y.max = max
    */

    loading.value = false
  })
}

onMounted(() => {
  userStore.show(route.params.id).then(({ data }) => {
    if (data.error) {
      return
    }

    user.value = data.user
  })
})

watch(from, () => {
  search()
})
watch(to, () => {
  search()
})

search()

</script>

<template>
  <div class="block-section">
    <div class="block-header">
      <span class="block-title">
        <div>Statistic #{{ user.id }} {{ user.name }}</div>
      </span>

      <div class="flex align-items-center justify-content-center">
        <Button label="Back" class="p-button-text p-button-plain" @click="$router.go(-1)" />
      </div>
    </div>
  </div>

  <br/>

  <div class="card p-fluid">
    <h5>จำนวนนับการทำงานกับข้อมูลสมาชิก</h5>
    <div class="formgrid grid">
      <div class="field col">
        <label for="name2">ตั้งแต่</label>
        <Calendar v-model="from" :showIcon="true" :showButtonBar="true" @change="search()"/>
      </div>
      <div class="field col">
        <label for="email2">จนถึง</label>
        <Calendar v-model="to" :showIcon="true" :showButtonBar="true" />
      </div>
    </div>
  </div>

  <ProgressBar v-if="loading" mode="indeterminate" style="height: 6px" />
  <Chart ref="chart" type="line" :data="ordersChart" :options="ordersChartOptions" />
</template>
